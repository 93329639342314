html {
  scroll-behavior: smooth;
}

.anchor {
  scroll-margin-top: 140px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
  font-family: "Gisbon"; /*Can be any text*/
  src: local("gisbon"), url("./fonts/gisbon.ttf") format("truetype");
}

@font-face {
  font-family: "Brutal Type Light"; /*Can be any text*/
  src: local("BrutalType-Light"), url("./fonts/BrutalType-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Brutal Type Regular"; /*Can be any text*/
  src: local("BrutalType"), url("./fonts/BrutalType.ttf") format("truetype");
}

@font-face {
  font-family: "Brutal Type Medium"; /*Can be any text*/
  src: local("BrutalType-Medium"), url("./fonts/BrutalType-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Brutal Type Bold"; /*Can be any text*/
  src: local("BrutalType-Bold"), url("./fonts/BrutalType-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Brutal Type Extra-Bold"; /*Can be any text*/
  src: local("BrutalType-ExtraBold"), url("./fonts/BrutalType-ExtraBold.ttf") format("truetype");
}

.prezi-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}
.prezi-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* MARKETPLACE */

.collection-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.suspense-img {
  width: calc((100vw - 10px) / 5);
  height: calc((100vh - 80px) / 5);
}

@keyframes backgroundColorPalette {
  0% {
    box-shadow: 4px 4px 7px -3px #55ff8d;
  }
  25% {
    box-shadow: 4px 4px 7px -2px #11ffff;
  }
  50% {
    box-shadow: 4px 4px 7px -3px #ea48ff;
  }
  75% {
    box-shadow: 4px 4px 7px -2px #ffe057;
  }
  100% {
    box-shadow: 4px 4px 7px -3px #ff9491;
  }
}
.info-box {
  animation-name: backgroundColorPalette;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  background-color: #343439;
  border-radius: 7px;
  box-shadow: 4px 4px 7px -2px #11ffff;
  width: 35vw;
  height: 36vw;
  max-width: 132px;
  max-height: 140px;
  transition: box-shadow 0.3s ease-in-out;
  margin: 0 1.8vw;
}

.shuffle-btn {
  transition: all 0.3s ease-in;
}

.shuffle-btn:hover {
  background-color: #f7f7f8a6;
  transform: scale(1.1, 1.1);
}

.disabled {
  visibility: hidden;
}
